import { createApp, nextTick } from "vue";

// styles

import "./assets/styles/tailwind.css";

import VueNextSelect from "vue-next-select";
import "./assets/styles/vue-next-select.css";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localeData from "dayjs/plugin/localeData";
import utc from "dayjs/plugin/utc";
import calendar from "dayjs/plugin/calendar";
import timezone from "dayjs/plugin/timezone";
import Ably from "ably";
import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";

import * as Sentry from "@sentry/vue";
import VueCookies from "vue-cookies";
import { createHead, useHead } from "@vueuse/head";
import VueGTag from "vue-gtag";
import OneSignalVuePlugin from "@onesignal/onesignal-vue3";
import NProgress from "nprogress";
import posthog from "posthog-js";
import "./assets/styles/nprogress.css";
import "./assets/styles/latex/css/katex.css";
import { store, utils } from "common-frontend";

// mouting point for the whole app

import App from "./App.vue";

// router

import router from "./router";

const { apiPlugin, posthogPlugin } = utils;

const app = createApp(App);
const head = createHead();
app.use(head);

app.use(router);
app.use(store);
app.use(apiPlugin);
app.use(posthogPlugin);

app.use(VueCookies, { expire: "7d" });
app.component("VueSelect", VueNextSelect);
if (!window.doNotTrack) {
  app.use(
    VueGTag,
    {
      config: { id: import.meta.env.VITE_GOOGLE_ID },
      includes: [{ id: import.meta.env.VITE_GOOGLE_ADS_ID }],
    },
    router,
  );
  store.$gtag = app.config.globalProperties.$gtag;
}

if (import.meta.env.VITE_SENTRY_DSN !== "null") {
  const integrations = [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
    }),
  ];
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations,
    tracePropagationTargets: [import.meta.env.VITE_BASE_URL, /^\//],
    tracesSampleRate: 0.1,
    logErrors: true,
    trackComponents: true,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });
}

const toastOptions = {
  transition: "Vue-Toastification__slideBlurred",
  maxToasts: 20,
  newestOnTop: true,
};
app.use(Toast, toastOptions);
app.use(OneSignalVuePlugin, {
  appId: import.meta.env.VITE_ONESIGNAL_APP_ID,
  safari_web_id: import.meta.env.VITE_ONESIGNAL_SAFARI_ID,
  notifyButton: {
    enable: false,
  },
});

dayjs.extend(relativeTime);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(calendar);
dayjs.extend(timezone);

const ably = new Ably.Realtime(import.meta.env.VITE_ABLY_KEY);

store.$dayjs = dayjs;

app.config.globalProperties.$ably = ably;
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$toast = useToast();
app.config.globalProperties.$useHead = useHead;

NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });

store.watch(
  (state) => state.loader.loading,
  (newVal, oldVal) => {
    if (newVal === 0) return NProgress.done();
    if (oldVal === 0) return NProgress.start();
    return NProgress.set(1.8 / Math.max(oldVal, newVal));
  },
);

router.beforeEach((to, from) => {
  if (to !== from) {
    store.state.loader.loading = 0;
  }
});

router.afterEach((to) => {
  nextTick(() => {
    posthog.capture("$pageview", {
      $current_url: import.meta.env.VITE_BASE_URL + to.fullPath,
    });
  });
});

app.mount("#app");

store.$app = app;
