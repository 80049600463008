import { createWebHistory, createRouter } from "vue-router";
import posthog from "posthog-js";

// all routes need names for us to properly push page transitions to PostHog
const routes = [
  {
    name: "Account",
    path: "/account",
    redirect: "/account/w",
    component: () => import("../layouts/Account.vue"),
    children: [
      {
        name: "CollaborationSpaceChooser",
        path: "/account/w",
        component: () =>
          import("../views/collaborationSpace/CollaborationSpaceChooser.vue"),
      },
      {
        name: "AccountSettings",
        path: "/account/settings",
        component: () => import("../views/account/Settings.vue"),
      },
      {
        path: "/account/w/:collaborationSpaceId",
        redirect: (to) => {
          return {
            name: "ClientCollaborationSpaceDashboard",
            params: { collaborationSpaceId: to.params.collaborationSpaceId },
          };
        },
      },
      {
        name: "ClientCollaborationSpaceDashboard",
        path: "/account/w/:collaborationSpaceId/dashboard",
        component: () =>
          import("../views/collaborationSpace/ClientDashboard.vue"),
      },
      {
        name: "ProfileList",
        path: "/account/profiles",
        component: () => import("../views/collaborationSpace/ProfileList.vue"),
      },
      {
        name: "Connections",
        path: "/account/connections",
        component: () => import("../views/account/Connections.vue"),
      },
      {
        name: "Messages",
        path: "/account/messages",
        component: () => import("../views/account/Messages.vue"),
      },
      {
        name: "MessageThread",
        path: "/account/messages/:messageThreadId",
        component: () => import("../views/account/Messages.vue"),
      },
      {
        name: "Notifications",
        path: "/account/notifications",
        component: () => import("../views/account/Notifications.vue"),
      },
      {
        name: "CollaborationSpaceSettings",
        path: "/account/w/:collaborationSpaceId/settings",
        component: () => import("../views/collaborationSpace/Settings.vue"),
      },
      {
        name: "EditProfile",
        path: "/account/edit-profile",
        component: () => import("../views/account/EditProfile.vue"),
      },
      {
        name: "PrivacyPreferences",
        path: "/account/privacy-preferences",
        component: () => import("../views/account/PrivacyPreferences.vue"),
      },
      {
        name: "MatchingPreferences",
        path: "/account/matching-preferences",
        component: () => import("../views/account/MatchingPreferences.vue"),
      },
      {
        name: "Payments",
        path: "/account/payments",
        component: () => import("../views/account/Payments.vue"),
      },
      {
        name: "ManageCalendar",
        path: "/account/calendar",
        component: () => import("../views/account/ManageCalendar.vue"),
      },
      {
        name: "SiteList",
        path: "/account/sites",
        component: () => import("../views/account/SiteList.vue"),
      },
      {
        name: "SiteDetail",
        path: "/account/sites/:hashedId",
        component: () => import("../views/account/SiteDetail.vue"),
      },
      {
        name: "ClientProjectDetail",
        path: "/account/w/:collaborationSpaceId/work/:projectId",
        component: () => import("../views/project/ProjectDetail.vue"),
      },
      {
        name: "FirmDetail",
        path: "/account/firm/:hashedId",
        component: () => import("../views/account/FirmDetail.vue"),
      },
      {
        name: "FirmSettings",
        path: "/account/firm/:hashedId/settings",
        component: () => import("../views/account/FirmSettings.vue"),
      },
      {
        name: "ExpertProjectDetail",
        path: "/account/dashboard/projects/:projectId",
        component: () => import("../views/project/ProjectDetail.vue"),
      },
      {
        name: "CollaborationSpaceResearchCompassWizard",
        path: "/account/w/:collaborationSpaceId/research-compass/",
        component: () =>
          import("../views/collaborationSpace/ResearchCompassWizard.vue"),
      },
      {
        name: "CollaborationSpaceResearchCompassDetail",
        path: "/account/w/:collaborationSpaceId/research-compass/:researchCompassId",
        component: () =>
          import("../views/collaborationSpace/ResearchCompassWizard.vue"),
      },
      {
        name: "CollaborationRequestDetail",
        path: "/account/w/:collaborationSpaceId/request/:collaborationRequestId",
        component: () =>
          import("../views/collaborationSpace/CollaborationRequestDetail.vue"),
      },
      {
        name: "CollaborationRequestDetailProfile",
        path: "/account/w/:collaborationSpaceId/request/:collaborationRequestId/profile/:profileId",
        component: () =>
          import("../views/collaborationSpace/CollaborationRequestDetail.vue"),
      },
      {
        name: "ResearcherDashboardProjects",
        path: "/account/dashboard/projects",
        component: () => import("../views/researcherDashboard/Projects.vue"),
      },
      {
        name: "ResearcherDashboardResearchCompass",
        path: "/account/dashboard/research-compass-list",
        component: () =>
          import("../views/researcherDashboard/ResearchCompass.vue"),
      },
      {
        name: "ResearcherDashboardResearchCompassWizard",
        path: "/account/dashboard/research-compass",
        component: () =>
          import("../views/researcherDashboard/ResearchCompassWizard.vue"),
      },
      {
        name: "ResearcherDashboardResearchCompassDetail",
        path: "/account/dashboard/research-compass/:researchCompassId",
        component: () =>
          import("../views/researcherDashboard/ResearchCompassWizard.vue"),
      },
      {
        name: "ResearcherDashboardResources",
        path: "/account/dashboard/resources",
        component: () => import("../views/researcherDashboard/Resources.vue"),
      },
      {
        name: "ResearchCommunityList",
        path: "/community",
        component: () =>
          import("../views/researchCommunity/ResearchCommunityList.vue"),
      },
      {
        name: "ResearchCommunityGuidelines",
        path: "/community/guidelines",
        component: () =>
          import("../views/researchCommunity/ResearchCommunityGuidelines.vue"),
      },
      {
        name: "ResearchCommunity",
        path: "/community/:slug",
        component: () =>
          import("../views/researchCommunity/ResearchCommunity.vue"),
      },
      {
        name: "RsearchCommunityPost",
        path: "/community/:slug/:postId",
        component: () =>
          import("../views/researchCommunity/ResearchCommunity.vue"),
      },
      {
        name: "ResearchCommunitySettings",
        path: "/community/:slug/settings",
        component: () =>
          import("../views/researchCommunity/ResearchCommunitySettings.vue"),
      },
      {
        name: "ResearchCommunityInviteAdmin",
        path: "/community/:slug/invites",
        component: () =>
          import("../views/researchCommunity/ResearchCommunityInviteAdmin.vue"),
      },
      {
        name: "MeetingScheduler",
        path: "/account/meeting/:meetingId/schedule",
        component: () => import("../views/meeting/Scheduler.vue"),
      },
    ],
  },
  {
    path: "/setup",
    component: () => import("../layouts/Setup.vue"),
    children: [
      {
        name: "AccountSetup",
        path: "/setup",
        component: () => import("../views/account/AccountSetup.vue"),
      },
    ],
  },
  {
    path: "/feed",
    component: () => import("../layouts/Feed.vue"),
    children: [
      {
        name: "FeedSearch",
        path: "/feed",
        component: () => import("../views/feed/FeedSearch.vue"),
      },
      {
        name: "ManageFeedSearchSubscriptionList",
        path: "/feed/manage",
        component: () =>
          import("../views/feed/ManageFeedSearchSubscriptionList.vue"),
      },
      {
        name: "ManageFeedSearchSubscription",
        path: "/feed/manage/:hashedId",
        component: () =>
          import("../views/feed/ManageFeedSearchSubscription.vue"),
      },
    ],
  },
  {
    path: "/feedback",
    component: () => import("../layouts/Feedback.vue"),
    children: [
      {
        name: "FeedbackSurvey",
        path: "/feedback",
        component: () => import("../views/feedback/FeedbackSurvey.vue"),
      },
    ],
  },
  {
    path: "/document",
    component: () => import("../layouts/Document.vue"),
    children: [
      {
        path: "/document/:hashedId/:viewKey",
        component: () => import("../views/document/DocumentHandler.vue"),
      },
    ],
  },
  {
    path: "/meeting",
    component: () => import("../layouts/Meeting.vue"),
    children: [
      {
        path: "/meeting/:appId/:meetingId",
        component: () => import("../views/meeting/Meeting.vue"),
      },
    ],
  },
  {
    path: "/content",
    component: () => import("../layouts/Basic.vue"),
    children: [
      {
        path: "/content/map",
        component: () => import("../views/Map.vue"),
      },
      {
        path: "/content/marketing-animation",
        component: () => import("../views/MarketingAnimation.vue"),
      },
      {
        path: "/content/calculator",
        component: () => import("../views/Calculator.vue"),
      },
    ],
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: () => import("../layouts/Auth.vue"),
    children: [
      {
        name: "Terms",
        path: "/terms",
        component: () => import("../views/Terms.vue"),
      },
      {
        name: "Privacy",
        path: "/privacy",
        component: () => import("../views/Privacy.vue"),
      },
      {
        name: "Login",
        path: "/auth/login",
        component: () => import("../views/auth/Login.vue"),
      },
      {
        name: "Logout",
        path: "/auth/logout",
        component: () => import("../views/auth/Logout.vue"),
      },
      {
        name: "ForgotPassword",
        path: "/auth/forgot-password",
        component: () => import("../views/auth/ForgotPassword.vue"),
      },
      {
        name: "ResetPassword",
        path: "/auth/reset-password/:hashedId/:code",
        component: () => import("../views/auth/ResetPassword.vue"),
      },
      {
        name: "SetPassword",
        path: "/auth/set-password/:hashedId/:code",
        component: () => import("../views/auth/SetPassword.vue"),
      },
      {
        name: "Register",
        path: "/auth/register",
        component: () => import("../views/auth/Register.vue"),
      },
      {
        name: "ExpertRegister",
        path: "/auth/expert-register",
        component: () => import("../views/auth/Register.vue"),
        props: { restrictedPersonType: "EXPERT" },
      },
      {
        name: "ClientRegister",
        path: "/auth/client-register",
        component: () => import("../views/auth/Register.vue"),
        props: { restrictedPersonType: "CLIENT" },
      },
      {
        name: "MagicLink",
        path: "/auth/link/:hashedId/:code",
        component: () => import("../views/auth/MagicLink.vue"),
      },
      {
        name: "VerifyEmail",
        path: "/auth/verify-email/:hashedId/:code",
        component: () => import("../views/auth/VerifyEmail.vue"),
      },
      {
        name: "VerifyInquiryEmail",
        path: "/auth/verify-collaboration-inquiry-email/:hashedId/:code",
        component: () =>
          import("../views/auth/VerifyCollaborationInquiryEmail.vue"),
      },
      {
        name: "AcceptInvitation",
        path: "/auth/accept-invitation/:code",
        component: () => import("../views/auth/AcceptInvitation.vue"),
      },
    ],
  },
  {
    path: "/auth/setup",
    component: () => import("../layouts/Auth.vue"),
    props: { loggedIn: true },
    children: [
      {
        name: "AuthSetup",
        path: "/auth/setup",
        component: () => import("../views/auth/AuthSetup.vue"),
      },
    ],
  },
  {
    name: "AuthRedirect",
    path: "/",
    redirect: "/auth/login",
  },
  { name: "GeneralRedirect", path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  if (from.path !== to.path) {
    posthog.capture("$pageleave");
  }
});

export default router;
